<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div></div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">
            {{ form.id ? 'Editar Sugestão' : 'Nova Sugestão' }}
          </h2>
        </div>
        <div class="modal__form--group">
          <r-select
            label="Categoria do material"
            v-model="form.category_id"
            :items="categories"
            class="col-12"
            labelName="title"
            placeholder="Categoria do material"
          />
          <r-input v-model="form.name" label="Nome" class="col-12" />
          <r-select
            label="Unidade de medida para coleta"
            v-model="form.unity_id"
            :items="unities"
            labelName="unity"
            class="col-12"
            :placeholder="'Selecionar unidade'"
          />

          <div @click="clickPhoto" class="material-photo">
            <img :src="photo" />
            <input
              ref="photoRef"
              accept="image/png,image/jpeg"
              type="file"
              hidden
              @change="handleImage"
            />
          </div>

          <div class="footer_modal">
            <button type="button" @click="register()" class="button_outline">
              {{ form.id ? 'Atualizar' : 'Cadastrar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import swal from 'sweetalert'

const defaultForm = {
  id: '',
  user_id: '',
  name: '',
  category_id: '',
  unity_id: '',
  photo: '',
  photo_url: 'svg/camera-retro-solid.svg'
}
export default {
  data: () => ({
    form: { ...defaultForm },
    cities: [],
    categories: [],
    unities: [],
    photo_base64: ''
  }),

  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('unity', { fetchUnities: 'fetch' }),
    ...mapActions('client', { save: 'save', fetchMaterials: 'fetch' }),
    async register () {
      if (!this.valid) {
        return swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos para salvar o registro.',
          icon: 'error',
          button: 'Ok'
        })
      }

      this.loading = true
      try {
        this.form.user_id = this.user.id
        this.form.status = -1
        const id = this.form.id
        const url = `item-suggestions${id ? '/' + id : ''}`
        const method = 'POST'
        const data = this.parseData(this.form)

        await axios.request({ method, url, data })

        swal({
          title: 'Sucesso!',
          text: `Cliente ${id ? 'atualizado' : 'cadastrado'} com sucesso.`,
          icon: 'success',
          timer: 4000
        }).then(() => {
          this.form = { ...defaultForm }
          this.$emit('change-modal', false)
          this.$emit('reload-list', true)
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    parseData () {
      const data = new FormData()

      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (Array.isArray(item)) {
          let count = 0
          item.map(subitem => {
            Object.keys(subitem).map(ik => {
              data.append(`${key}[${count}][${ik}]`, subitem[ik])
            })
            count++
          })
        } else {
          data.append(key, item)
        }
      })
      return data
    },
    async getUnities () {
      const url = 'unities'
      const { data } = await this.$http.get(url)
      this.unities = data.data
    },
    async getCategories () {
      const url = 'categories'
      const { data } = await this.$http.get(url)
      this.categories = data.data
    },
    clickPhoto () {
      this.$refs.photoRef.click()
    },
    handleImage (e) {
      if (this.loading) return
      this.form.photo = {}
      const files = e.target.files
      if (!files.length) return
      const [file] = files

      const { type, size } = file
      const acceptedTypes = ['image/png', 'image/jpeg']

      if (!acceptedTypes.includes(type)) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'Os arquivos aceitos são no formato PNG e JPG.',
          icon: 'error'
        })
      }

      if (size > 2097152) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'O arquivo deve ser menor que 2MB.',
          icon: 'error'
        })
      }

      this.photo = file
      const render = new FileReader()
      render.onload = e => {
        this.photo_base64 = e.target.result
      }
      render.readAsDataURL(file)
    }
  },

  computed: {
    ...mapGetters('unity', ['unities']),
    ...mapGetters('province', ['provinces']),
    ...mapGetters('user', ['user']),
    valid () {
      const form = this.form
      if (!form.name) return false
      return true
    },
    photo: {
      get () {
        return this.photo_base64 || this.form.photo || this.form.photo_url
      },
      set (val) {
        this.form.photo = val
      }
    }
  },
  props: {
    modal: Boolean,
    editing: Object
  },
  mounted () {
    this.user_id = this.user.id
  },
  created () {
    this.getUnities()
    this.getCategories()
    this.fetchProvinces()
    this.fetchUnities()
  },
  components: {
    baseModal: () => import('@/components/modal'),
    RSelect: () => import('@/components/select.vue'),
    RInput: () => import('@/components/input.vue')
  },
  watch: {
    async 'form.province_id' (val) {
      this.cities = []
      if (!val) return
      const url = `cities?province_id=${val}`
      const { data } = await this.$http.get(url)
      this.cities = data
      if (!this.cities.map(i => i.id).includes(this.form.city_id)) {
        this.form.city_id = ''
      }
    },
    editing (val) {
      this.form = { ...val }
    },
    modal (val) {
      if (!val) {
        setTimeout(() => {
          this.form = { ...defaultForm }
          this.photo_base64 = ''
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.material-photo {
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 0.5rem;
  cursor: pointer;
}

.material-photo img {
  max-width: 85%;
  max-height: 85%;
}
</style>
